<div class="vertical-menu" [ngStyle]="sidebarStyle">

  <div data-simplebar class="h-100">

    <!--- Sidemenu -->
    <div id="sidebar-menu">
      <!-- Left Menu Start -->
      <ul class="metismenu list-unstyled" id="side-menu">
        <li class="menu-title">Menu</li>
        <li>
          <a href="/dashboard/administrator" class="waves-effect">
            <i class="bx bx-home-circle"></i>
            <span>Accueil</span>
          </a>
        </li>
        <ng-container *ngIf="profile_id!=3">
        <li>
          <a href="javascript: void(0);" class="has-arrow waves-effect">
            <i class="bx bx-user"></i>
            <span>Utilisateurs</span>
          </a>
          <ul class="sub-menu" aria-expanded="false" >
            <ng-container *ngIf="profile_id==11">
              <li><a href="/dashboard/administrator/clients">Administrateurs de site</a></li>
            </ng-container>
            <ng-container *ngIf="profile_id==11 || profile_id==1 || profile_id==12">
              <li><a href="dashboard/administrator/superviseurs">Superviseurs</a></li>
            </ng-container>
            <ng-container *ngIf="profile_id==11 || profile_id==1 || profile_id==12 || profile_id==2 || profile_id==4 || profile_id==5 || profile_id==6 || profile_id==9 || profile_id==10">
              <li><a href="dashboard/administrator/verificateurs">Vérificateurs</a></li>
            </ng-container>
            <ng-container *ngIf="profile_id!=3">
              <li><a href="dashboard/administrator/intervenants">Apprenants/Intervenants</a></li>
            </ng-container>
          </ul>
        </li>
        </ng-container>
        <ng-container *ngIf="profile_id==11">
          <li>
            <a href="javascript: void(0);" class="has-arrow waves-effect">
              <i class="bx bx-briefcase-alt-2"></i>
              <span>Gestion</span>
            </a>
            <ul class="sub-menu" aria-expanded="false" >
              <li><a href="dashboard/administrator/verifications">Vérifications</a></li>
              <li><a href="/dashboard/administrator/certifications">Certifications</a></li>
              <li><a href="dashboard/administrator/sous-certifications">Sous-certificats</a></li>
              <li><a href="dashboard/administrator/choice_screen">Ecran de choix</a></li>
            </ul>
          </li>
        </ng-container>
        <ng-container *ngIf="profile_id==11 || profile_id==1 || profile_id==12">
          <li>
            <a href="/dashboard/administrator/formulaire">
              <i class="bx bxs-file-html"></i>
              <span>Formulaires</span>
            </a>
          </li>
          <li>
            <a href="/dashboard/administrator/import">
              <i class="bx bxs-cloud-upload"></i>
              <span>Imports</span>
            </a>
          </li>
        </ng-container>
        <ng-container *ngIf="profile_id!==3">
          <li>
            <a href="/dashboard/administrator/exports">
              <i class="bx bxs-cloud-download"></i>
              <span>Exports</span>
            </a>
          </li>
          <li>
            <a style="cursor: pointer;" (click)="verificateur_view(admin_login)">
              <i class="bx bx-log-in-circle"></i>
              <span>Connexion en tant que Vérificateur</span>
            </a>
          </li>
          <li>
            <a style="cursor: pointer;" (click)="sso_certikap()">
              <i class="bx bx-log-in-circle"></i>
              <span>Connexion LMS</span>
            </a>
          </li>
        </ng-container>
        <ng-container *ngIf="profile_id==3">
          <li><a style="cursor: pointer;" (click)="viewCertificats(connected_id)">Mes certificats</a></li>
          <li><a style="cursor: pointer;" (click)="sso_active()">Mes formations</a></li>
        </ng-container>
      </ul>
    </div>
    <!-- Sidebar -->
  </div>
</div>
