<app-header></app-header>
<app-sidebar></app-sidebar>
<div class="main-content">
  <div class="page-content">
    <div class="container-fluid">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-flex align-items-center justify-content-between">
            <h4 class="mb-0 font-size-18">
              <i class="bx bx-home-circle"></i>  Bienvenue dans votre espace
              <app-fildariane></app-fildariane>
            </h4>
          </div>
        </div>
      </div>
      <!-- end page title -->
      <!-- end row -->
      <div class="row">
        <div class="card col-xl-12">
          <div class="card-body">
            <h4 class="card-title mb-4">Import</h4>
            <ng-container *ngIf="profile_id !=1 && profile_id !=12">
              <div class="alert alert-danger" style="margin-left: 12px">L'import de fichier s'effectue seulement avec un compte administrateur de site</div>
            </ng-container>
            <div>
              <div class="input-group">
                <div class="file-upload col-md-12">
                  <input type="file" (change)="onFileSelected($event)" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />

                </div>
                <div class="file-upload col-md-12 mt-3">
                  <button (click)="onUpload()" [disabled]="!selectedFile"
                          class="btn btn-primary waves-effect waves-light">
                    Importer le fichier
                  </button>
                </div>
              </div>
              <table>
                <tbody>
                </tbody>
              </table>



            </div>
          </div>
          <!-- end card -->

          <!-- end card -->
        </div>

      </div>
      <!-- end row -->
    </div>
    <!-- container-fluid -->
  </div>
</div>
<!--</div>-->
<!-- End Page-content -->
<app-footer></app-footer>
