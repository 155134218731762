import { Component, OnInit } from '@angular/core';
import {ImportService} from '../../../../services/import.service';
import {UserService} from '../../../../services/user.service';
import {Subscription} from "rxjs";
import {ConfigService} from "../../../../services/config.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import Swal from "sweetalert2";
import {HttpClient} from "@angular/common/http";



@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.css']
})
export class ImportComponent implements OnInit {

  // tslint:disable-next-line:variable-name
  public profile_id = parseInt(localStorage.getItem('profile_id'));
  // tslint:disable-next-line:variable-name
  public user_conntected = JSON.parse(localStorage.getItem('currentUser'));
  public site_id = JSON.parse(localStorage.getItem('site_id'));

  selectedFile: File | null = null;
  // En-têtes attendus obligatoires
  expectedHeaders: string[] = ['login', 'mot de passe', 'nom', 'prenom', 'email','date de debut','date de fin','societe',
  'Manager email','Manager nom','Manager prenom','statut','langue','groupe(s)','session(s)'];

  constructor(public translate: TranslateService,
              private importService: ImportService,
              private userService: UserService,
              private config: ConfigService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private spinner: NgxSpinnerService,
              private http: HttpClient
  ) {}

  ngOnInit() {
    this.config.checkUSerAccessByProfile(this.profile_id);
    if (!localStorage.getItem('currentUser')) {
      this.router.navigate(['/']);
    }
  }

  // Fonction appelée lorsque l'utilisateur sélectionne un fichier
  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    this.selectedFile = file;

    if (this.profile_id !== 1 && this.profile_id !== 12) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'L\'import de fichier s\'effectue seulement avec un compte administrateur de site',
      });
      this.selectedFile = null;
      return;
    }

    if (!file) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Aucun fichier sélectionné.',
      });
      this.selectedFile = null;
      return;
    }

    // Vérifier l'extension du fichier
    const allowedExtensions = ['csv', 'xlsx', 'xls'];
    let fileExtension = file.name.split('.').pop();
    if (fileExtension) {
      fileExtension = fileExtension.toLowerCase();
    }

    if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Format de fichier non valide. Veuillez importer un fichier CSV ou Excel.',
      });
      this.selectedFile = null;
      return;
    }

    const reader = new FileReader();
    reader.onload = (e: any) => {
      const fileContent = e.target.result as string;

      const lines = fileContent.split('\n');
      const headers = lines[0].split(',').map(header => header.trim().toLowerCase());
      console.log(headers)
      console.log(lines)
      if (!this.hasRequiredHeaders(headers)) {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Le fichier ne contient pas tous les en-têtes obligatoires.',
        });
        this.selectedFile = null;
      }
    };
    console.log(this.selectedFile);

    reader.readAsText(file);
  }


  hasRequiredHeaders(headers: string[]): boolean {
    return this.expectedHeaders.every(expectedHeader =>
      headers.includes(expectedHeader.toLowerCase())
    );
  }


  // Fonction appelée pour télécharger le fichier vers le backend
  onUpload(): void {
    if (!this.selectedFile) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Aucun fichier sélectionné.'
      });
      return;
    }

    const formData = new FormData();
    formData.append('file', this.selectedFile);
    console.log(this.site_id)
    formData.append('user_site', this.site_id);

    this.spinner.show();
    this.http.post(this.config.urlDEV + '/import_csv/add', formData)
      .subscribe((res: any) => {
        if (res.success === true) {
          this.spinner.hide();
          Swal.fire({
            title: 'L\'import a bien été réalisé !',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK',
          });
      } else {
          this.spinner.hide();
          Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Erreur lors de l\'importation du fichier. Veuillez vérifier le fichier de log.'
      });
      }
    },(error) => {
        this.spinner.hide();
        console.error('Erreur lors de l\'importation du fichier', error);
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Erreur lors de l\'importation du fichier.'
        });
      }
    );
  }
}
